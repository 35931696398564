import * as React from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

export default function Loading() {
    return (
        <Box sx={{ overflow: "hidden" }}>
            <CircularProgress color="secondary" size={70} />
        </Box>
    );
}
