import React from "react";
import { Checkbox, ListItem, ListItemText, Typography } from "@mui/material";
import { convertToDateTimeWithoutYear } from "../../utils/dateUtils";

export default function ToDoListItem({ item, handleCheckboxChange }) {
    return (
        <ListItem sx={{ padding: 0, flex: 1 }}>
            <Checkbox
                edge="start"
                checked={item.completed}
                onChange={() => handleCheckboxChange(item.id)}
                sx={{
                    "&.Mui-checked": {
                        color: "grey",
                    },
                }}
            />
            <ListItemText
                primary={
                    <Typography
                        sx={{
                            fontSize: "0.9rem",
                            whiteSpace: "pre-line", // 줄바꿈을 유지하도록 설정
                            color: item.completed ? "grey" : "black",
                            textDecoration: item.completed ? "line-through" : "none", // 체크박스 클릭 시 취소선
                        }}
                    >
                        {item.title}
                    </Typography>
                }
                secondary={
                    <Typography
                        sx={{
                            color: "grey",
                            fontSize: "0.8rem",
                            textDecoration: item.completed ? "line-through" : "none", // 체크박스 클릭 시 취소선
                        }}
                    >
                        {convertToDateTimeWithoutYear(item.createdAt)}
                    </Typography>
                }
            />
        </ListItem>
    );
}
