import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routers from "./Routers";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { LocalStorageProvider } from "./context/LocalStorageContext";
import { UserProvider } from "./context/UserContext";

function App() {
    return (
        <LocalStorageProvider>
            <UserProvider>
                <BrowserRouter>
                    <div className="app">
                        <Header />
                        <div className="router">
                            <div className="router-content">
                                <Routers />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </BrowserRouter>
            </UserProvider>
        </LocalStorageProvider>
    );
}

export default App;
