import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import FortuneBox from "./FortuneBox";

export default function TodayFortuneNotificationDetail({ todayFortuneJson }) {
    const fortuneResponse = JSON.parse(todayFortuneJson);
    const [tabValue, setTabValue] = React.useState("TODAY");

    return (
        <Box sx={{ borderRadius: 1, mb: 1 }}>
            <Typography gutterBottom sx={{ mb: 0, fontSize: "5vw", fontFamily: "Pretendard-Regular", fontWeight: "bold" }}>
                오늘의 운세
            </Typography>
            {fortuneResponse.result ? (
                <Box>
                    <Typography gutterBottom sx={{ mt: 0, fontSize: "3vw", fontFamily: "Pretendard-Regular" }}>
                        {fortuneResponse.result.day.title}
                    </Typography>
                    <Tabs
                        value={tabValue}
                        onChange={(event, value) => setTabValue(value)}
                        textColor="inherit"
                        indicatorColor="primary"
                        sx={{
                            ml: -1,
                            ".MuiTabs-indicator": {
                                top: "auto", // 기본값을 재정의
                                bottom: 10, // 인디케이터의 위치를 조정
                            },
                        }}
                    >
                        {fortuneResponse.result.day && (
                            <Tab
                                sx={{ minWidth: "50px", width: "50px", p: 0, fontSize: "1.0625rem", fontFamily: "Pretendard-Regular" }}
                                value="TODAY"
                                label="오늘"
                            />
                        )}
                        {fortuneResponse.result.tomorrow && (
                            <Tab
                                sx={{
                                    minWidth: "50px",
                                    width: "50px",
                                    p: 0,
                                    pl: 0.5,
                                    pr: 0.5,
                                    position: "relative",
                                    "&::before, &::after": {
                                        content: '""',
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        height: "35%", // 선의 길이 조절
                                        borderLeft: "1.5px solid rgba(0, 0, 0, 0.23)",
                                        borderRight: "1.5px solid rgba(0, 0, 0, 0.23)",
                                    },
                                    fontSize: "1.0625rem",
                                    fontFamily: "Pretendard-Regular",
                                }}
                                value="TOMORROW"
                                label="내일"
                            />
                        )}
                        {fortuneResponse.result.week && (
                            <Tab
                                sx={{
                                    minWidth: "60px",
                                    width: "60px",
                                    p: 0,
                                    pl: 0.5,
                                    pr: 0.5,
                                    position: "relative",
                                    "&::before, &::after": {
                                        content: '""',
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        height: "35%", // 선의 길이 조절
                                        borderRight: "1.5px solid rgba(0, 0, 0, 0.23)",
                                    },
                                    fontSize: "1.0625rem",
                                    fontFamily: "Pretendard-Regular",
                                }}
                                value="WEEK"
                                label="이번주"
                            />
                        )}
                        {fortuneResponse.result.month && (
                            <Tab
                                sx={{
                                    minWidth: "60px",
                                    width: "60px",
                                    p: 0,
                                    fontSize: "1.0625rem",
                                    fontFamily: "Pretendard-Regular",
                                }}
                                value="MONTH"
                                label="이번달"
                            />
                        )}
                    </Tabs>
                    <Box>
                        {tabValue === "TODAY" && fortuneResponse.result.day && <FortuneBox fortune={fortuneResponse.result.day} />}
                        {tabValue === "TOMORROW" && fortuneResponse.result.tomorrow && <FortuneBox fortune={fortuneResponse.result.tomorrow} />}
                        {tabValue === "WEEK" && fortuneResponse.result.week && <FortuneBox fortune={fortuneResponse.result.week} />}
                        {tabValue === "MONTH" && fortuneResponse.result.month && <FortuneBox fortune={fortuneResponse.result.month} />}
                    </Box>
                </Box>
            ) : (
                <Typography gutterBottom sx={{ mt: 1, fontSize: "4vw", fontFamily: "Pretendard-Regular" }}>
                    오늘의 운세가 아직 준비되지 않았습니다. 잠시 후 다시 시도해주세요.
                </Typography>
            )}
        </Box>
    );
}
