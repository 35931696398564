import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Alert,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import { convertToDateTimeWithoutYear } from "../../utils/dateUtils";
import { services } from "../../data/services";
import Loading from "../Loading";
import { TablePaginationActions } from "@mui/base";
import AdminUserStatisticsChart from "./AdminUserStatisticsChart";
import AdminDailyActiveUsersChart from "./AdminDailyActiveUsersChart";

export default function AdminUsers() {
    const [users, setUsers] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertString, setAlertString] = useState("");
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sort, setSort] = React.useState("id");

    useEffect(() => {
        axios.get(`/api/users?page=${page}&size=${rowsPerPage}&sort=${sort}`).then((response) => {
            setUsers(response.data.content);
            console.log(response.data.content);
            setTotalElements(response.data.totalElements);
        });
    }, [page, rowsPerPage, sort]);

    const updateUserNote = (user) => {
        axios
            .patch(`/api/users/${user.id}`, {
                note: user.note,
            })
            .then((res) => {
                setAlertOpen(true);
                setAlertString("저장 완료");
            })
            .catch((e) => {
                setAlertOpen(true);
                setAlertString("저장 실패 " + e.data);
            });
    };

    const handleNoteChange = (id, value) => {
        setUsers((prevUsers) => prevUsers.map((user) => (user.id === id ? { ...user, note: value } : user)));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return users.length > 0 ? (
        <Box id={"admin-users"}>
            <AdminDailyActiveUsersChart />
            <AdminUserStatisticsChart />
            <TableContainer component={Paper} sx={{}} style={{ overflow: "auto" }}>
                <Table sx={{ width: "100%" }} stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>id</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>createdAt</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>loginType</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>email</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>nickname</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>note</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>lastLoginDate</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>알림</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>services</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>설정 정보</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user, index) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.id}</TableCell>
                                <TableCell>{convertToDateTimeWithoutYear(user.createdAt)}</TableCell>
                                <TableCell>{user.loginType}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.nickname}</TableCell>
                                <TableCell>
                                    <TextField
                                        sx={{ width: "70px", p: 0 }}
                                        value={user.note}
                                        onChange={(e) => handleNoteChange(user.id, e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        InputProps={{ style: { fontSize: "12px", p: 0 } }}
                                    />
                                </TableCell>
                                <TableCell>{convertToDateTimeWithoutYear(user.lastLoginDate)}</TableCell>
                                <TableCell>{user.expoPushToken ? "허용" : "거부"}</TableCell>
                                <TableCell>
                                    {user.services
                                        .map((service) => {
                                            return services.find((it) => it.type === service.type).name;
                                        })
                                        .join(", ")}
                                </TableCell>
                                <TableCell sx={{ whiteSpace: "pre-line" }}>
                                    {user.services
                                        .map((service) => {
                                            if (service.type === "TODAY_FORTUNE") {
                                                return `• ${service.birthDate} ${service.gender === "F" ? "여성" : "남성"}`;
                                            } else if (service.type === "WEATHER") {
                                                return `• ${service.address}`;
                                            }
                                        })
                                        .join("\n")}
                                </TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" size="small" onClick={() => updateUserNote(user)}>
                                        저장
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <FormControl variant="standard" sx={{ minWidth: 80 }}>
                                <InputLabel id="sort-label">정렬 기준</InputLabel>
                                <Select labelId="sort-label" value={sort} onChange={(e) => setSort(e.target.value)}>
                                    <MenuItem value="id">가입 일자</MenuItem>
                                    <MenuItem value="lastLoginDate">마지막 로그인일자</MenuItem>
                                </Select>
                            </FormControl>
                            <TablePagination
                                rowsPerPageOptions={[10, 30, 50, 100, { label: "All", value: 99999999 }]}
                                colSpan={3}
                                count={totalElements}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        inputProps: {
                                            "aria-label": "rows per page",
                                        },
                                        native: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Snackbar
                open={alertOpen}
                autoHideDuration={2000}
                onClose={() => {
                    setAlertOpen(false);
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity="info"
                    sx={{ mt: 5, width: "100%", display: "flex", alignItems: "center", fontSize: "20px", textAlign: "center" }}
                    onClose={() => {
                        setAlertOpen(false);
                    }}
                >
                    {alertString}
                </Alert>
            </Snackbar>
        </Box>
    ) : (
        <Loading />
    );
}
