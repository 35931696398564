import { Box } from "@mui/material";
import React from "react";

export default function WeatherImage({ src, alt, size, mt }) {
    return (
        <Box
            sx={{
                mt: mt,
                width: size, // 컨테이너의 너비
                height: size, // 컨테이너의 높이
                display: "flex",
                justifyContent: "center", // 수평 중앙 정렬
                alignItems: "center", // 수직 중앙 정렬
            }}
        >
            <img
                src={src}
                alt={alt}
                style={{ maxWidth: "100%", maxHeight: "100%" }} // 이미지 크기 조절
            />
        </Box>
    );
}
