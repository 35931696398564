import { Route, Routes } from "react-router-dom";
import Intro from "./pages/Intro";
import LoginSuccess from "./pages/LoginSuccess";
import Home from "./pages/Home";
import Notifications from "./pages/Notifications";
import NotFound from "./pages/NotFound";
import UserSetting from "./pages/UserSetting";
import LoginFailed from "./pages/LoginFailed";
import NotificationDetail from "./pages/NotificationDetail";
import AdminHome from "./pages/admin/AdminHome";

function Routers() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/intro" element={<Intro />} />
            <Route path="/login-success" element={<LoginSuccess />} />
            <Route path="/login-failed" element={<LoginFailed />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/notifications/:id" element={<NotificationDetail />} />
            <Route path="/settings" element={<UserSetting />} />
            <Route path="/admin" element={<AdminHome />} />
            <Route path="*" element={<NotFound />} /> {/* 찾을 수 없는 페이지에 대한 라우트 */}
        </Routes>
    );
}

export default Routers;
