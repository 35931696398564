import { Box, Button, FormControl, FormLabel } from "@mui/material";
import React from "react";
import { useServiceSetting } from "../hook/useServiceSetting";
import { LocationOnIcon } from "../data/icons";
import Typography from "@mui/material/Typography";
import DaumPostcodeModal from "./DaumPostcodeModal";

export default function WeatherSetting({ address, setAddress }) {
    const { daumPostcodeModalOpen, setDaumPostcodeModalOpen } = useServiceSetting();

    return (
        <Box sx={{ pl: 4 }}>
            <FormControl fullWidth sx={{ mb: 2, textAlign: "left" }}>
                <Button
                    sx={{ fontWeight: 500 }}
                    variant="contained"
                    color="primary"
                    startIcon={<LocationOnIcon />}
                    onClick={() => setDaumPostcodeModalOpen(true)}
                >
                    위치 설정
                </Button>
                {address && (
                    <Box sx={{ mt: 1 }}>
                        <FormLabel sx={{ color: "black", fontSize: "1rem", mb: 1 }}>설정된 위치</FormLabel>
                        <Typography sx={{ mt: 0.5, p: 2, border: 1, borderColor: "rgba(0, 0, 0, 0.23)", borderRadius: 1, fontSize: "1rem" }}>
                            {address}
                        </Typography>
                    </Box>
                )}
            </FormControl>
            <DaumPostcodeModal open={daumPostcodeModalOpen} handleClose={() => setDaumPostcodeModalOpen(false)} setAddress={setAddress} />
        </Box>
    );
}
