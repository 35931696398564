import * as React from "react";
import { Checkbox, FormControlLabel, IconButton, Tooltip } from "@mui/material";
import { HelpOutlineIcon } from "../data/icons";

export default function SettingFormControlLabel({ service }) {
    return (
        <FormControlLabel
            sx={{ marginBottom: "7px" }}
            control={<Checkbox checked={service.checked} onChange={(event) => service.onChange(event.target.checked)} />}
            label={
                <div className="setting-item">
                    {service.name}
                    <Tooltip
                        title={service.tooltipTitle}
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                        placement="top-start"
                        arrow
                        PopperProps={{
                            modifiers: [
                                {
                                    name: "offset",
                                    options: {
                                        offset: [0, -12], // x축으로 0, y축으로 10 이동
                                    },
                                },
                            ],
                        }}
                    >
                        <IconButton size="small" style={{ marginLeft: "10px" }}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            }
        />
    );
}
