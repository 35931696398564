import React from "react";
import { QuestionMarkIcon } from "../data/icons";
import { Box, Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function NotFound() {
    return (
        <Box sx={{ width: "95vw" }}>
            <Card variant="contained" sx={{ borderRadius: "12px", marginBottom: "18px" }}>
                <CardContent sx={{ margin: "10px", padding: "16px", "&:last-child": { paddingBottom: "16px" } }}>
                    <QuestionMarkIcon sx={{ fontSize: "30vw", mb: 2 }} />
                    <Typography sx={{ fontSize: "5vw", fontFamily: "Cafe24Supermagic-Bold-v1.0" }}>페이지를 찾을 수 없습니다.</Typography>
                </CardContent>
            </Card>
        </Box>
    );
}
