import React from "react";
import ApexCharts from "react-apexcharts";
import { findValueByCategory, renderWeatherImage } from "../../../utils/weatherUtils";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { OpacityIcon, WaterDropIcon } from "../../../data/icons";
import { WaterDropOutlined } from "@mui/icons-material";

export default function TemperatureChart({ weathers, times }) {
    const temperatures = weathers.map((weather) => findValueByCategory(weather.items, "TMP"));
    const precipitations = weathers.map((weather) => findValueByCategory(weather.items, "POP"));

    const state = {
        series: [
            {
                name: "기온",
                data: temperatures,
            },
        ],
        options: {
            chart: {
                minWidth: "100%",
                type: "line", // 라인 차트로 변경
                toolbar: {
                    show: false, // 툴바 숨기기
                },
                zoom: {
                    enabled: false, // 줌 기능 비활성화
                },
            },
            colors: ["#FFC0A9"], // 차트 색상 설정
            dataLabels: {
                enabled: true, // 데이터 레이블 활성화
                offsetY: -6, // 레이블을 데이터 포인트 위로 조금 올리기
                background: "none", // 배경 제거
                style: {
                    colors: ["black"], // 글자 색상을 검정색으로 변경
                    fontSize: "0.9375rem", // 레이블의 글자 크기 설정
                    fontWeight: 500,
                    fontFamily: "Pretendard-Regular",
                },
                formatter: function (val) {
                    return val + "°"; // 데이터 포맷 변경
                },
            },
            stroke: {
                curve: "straight",
            },
            markers: {
                size: 5, // 점의 크기 설정
            },
            xaxis: {
                show: false, // X축 숨기기
                categories: [], // X축 카테고리 설정 제거
                labels: {
                    show: false, // 라벨 숨기기
                },
                axisBorder: {
                    show: false, // X축 테두리 숨기기
                },
                axisTicks: {
                    show: false, // X축 눈금 숨기기
                },
            },
            yaxis: {
                show: false, // Y축 숨기기
            },
            grid: {
                show: false, // 그리드 숨기기
            },
            tooltip: {
                enabled: false, // 툴팁 숨기기
            },
            legend: {
                show: false, // 범례 숨기기
            },
        },
    };

    return (
        <Box sx={{ ml: 0.5 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 1, marginLeft: -0.5 }}>
                {times.map((time, index) => (
                    <Box key={index} sx={{ textAlign: "left", minWidth: 55.45 }}>
                        <Typography component="p" sx={{ m: 0, fontSize: 14, ml: 0.6 }}>
                            {time}
                        </Typography>
                        <Typography sx={{ margin: "0 auto" }}>{renderWeatherImage(weathers[index].items, 30)}</Typography>
                    </Box>
                ))}
            </Box>
            <ApexCharts options={state.options} series={state.series} type="line" height={80} width={weathers.length * 55} />
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, ml: -0.9, mt: -3 }}>
                {precipitations.map((precipitation, index) => (
                    <Box key={index} sx={{ textAlign: "left", minWidth: 55.5, display: "flex", alignItems: "center" }}>
                        {Number(precipitation) < 20 ? (
                            <WaterDropOutlined sx={{ m: 0, fontSize: 16, color: "grey" }} />
                        ) : Number(precipitation) >= 70 ? (
                            <WaterDropIcon sx={{ m: 0, fontSize: 16, color: "grey" }} />
                        ) : (
                            <OpacityIcon sx={{ m: 0, fontSize: 16, color: "grey" }} />
                        )}
                        <Typography component="p" sx={{ m: 0, ml: -0.2, fontSize: 14 }}>
                            {precipitation}%
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
