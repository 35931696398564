import React from "react";
import { AutoAwesomeIcon, CurrencyBitcoinIcon, DoneIcon, NewspaperIcon, TrendingUpIcon, WbSunnyIcon } from "../data/icons";

export const getIcon = (type) => {
    switch (type) {
        case "TODAY_FORTUNE":
            return <AutoAwesomeIcon style={{ color: "purple" }} />;
        case "WEATHER":
            return <WbSunnyIcon style={{ color: "gold" }} />;
        case "NEWS":
            return <NewspaperIcon style={{ color: "blue" }} />;
        case "STOCK":
            return <TrendingUpIcon />;
        case "BITCOIN":
            return <CurrencyBitcoinIcon />;
        default:
            return <DoneIcon style={{ color: "black" }} />;
    }
};
