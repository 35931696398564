import { Box, Button, Card, CardContent, CardMedia, Grid } from "@mui/material";
import React, { useState } from "react";
import Typography from "@mui/material/Typography";

export default function NewsNotificationDetail({ newsJson }) {
    const news = JSON.parse(newsJson).content;
    const [showCount, setShowCount] = useState(5);
    const toggleMore = () => {
        if (showCount >= news.length) setShowCount(5);
        setShowCount(showCount + 20);
    };

    return (
        <Box sx={{ borderRadius: 1, mb: 1 }}>
            <Typography gutterBottom sx={{ mb: 0, fontSize: "5vw", fontFamily: "Pretendard-Regular", fontWeight: "bold" }}>
                뉴스
            </Typography>
            {news.length !== 0 ? (
                <>
                    <Typography gutterBottom sx={{ mt: 0, fontSize: "3vw", fontFamily: "Pretendard-Regular" }}>
                        언론사별 가장 많이 본 뉴스
                    </Typography>
                    <Grid container spacing={1}>
                        {news.slice(0, showCount).map((item, index) => (
                            <Grid item xs={12} key={index} onClick={() => window.open(item.link, "_blank")}>
                                <Card sx={{ display: "flex", alignItems: "center" }}>
                                    <CardContent sx={{ flex: 1, minWidth: 0, p: 2 }}>
                                        <Typography
                                            gutterBottom
                                            sx={{
                                                fontFamily: "Pretendard-Regular",
                                                fontSize: "0.875rem",
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 2,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                    </CardContent>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 95, height: 65 }}
                                        image={
                                            item.imageUrl ||
                                            "https://png.pngtree.com/element_our/20190528/ourlarge/pngtree-question-mark-convenient-icon-image_1145237.jpg"
                                        }
                                        alt={item.title}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    {showCount < news.length && (
                        <Button onClick={toggleMore} variant="outlined" sx={{ mt: 1, mr: 2, fontWeight: "bold", fontFamily: "Pretendard-Regular" }}>
                            더 보기
                        </Button>
                    )}
                    {showCount !== 5 && (
                        <Button onClick={() => setShowCount(5)} variant="outlined" sx={{ mt: 1, fontWeight: "bold", fontFamily: "Pretendard-Regular" }}>
                            접기
                        </Button>
                    )}
                </>
            ) : (
                <Typography gutterBottom sx={{ mt: 0, fontSize: "3.5vw", fontFamily: "Pretendard-Regular" }}>
                    언론사별 가장 많이 본 뉴스가 제공되지 않는 시간입니다.
                </Typography>
            )}
        </Box>
    );
}
