import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Box, ButtonBase, Card, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { MarkEmailReadOutlinedIcon, MarkEmailUnreadOutlinedIcon } from "../data/icons";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { useLocalStorage } from "../context/LocalStorageContext";
import axios from "axios";
import { convertToDateTime } from "../utils/dateUtils";
import { useInView } from "react-intersection-observer";
import NotificationDetailModal from "../components/notifications/NotificationDetailModal";

export default function Notifications() {
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState(false);
    const { token } = useLocalStorage();
    // ref 를 div에 걸어주면 해당 요소가 보이면 inView가 true 로, 안 보이면 false로 자동으로 변경
    const [ref, inView] = useInView();
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [lastId, setLastId] = useState(99999999999);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [detailNotificationId, setDetailNotificationId] = useState(-1);

    const fetchItems = useCallback(async () => {
        if (loading || !hasMore) return;
        setLoading(true);
        await axios
            .get(`/api/notification-messages/me?lastId=${lastId}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                // 초기 로딩 시 Loading 컴포넌트를 띄우기 위해 false로 설정해놓은 값을 이전 배열과 API response를 이어붙이기 위해 빈 배열로 변경
                if (!notifications) setNotifications([]);
                setNotifications((prev) => [...prev, ...response.data.content]);

                if (response.data.content.length > 0) setLastId(response.data.content[response.data.content.length - 1].id);
                else setHasMore(false);
            })
            .finally(() => setLoading(false));
    });

    useEffect(() => {
        if (!token) {
            navigate("/intro");
        }
        fetchItems().then();
    }, [token, navigate]);

    useEffect(() => {
        // 뷰포트 내에 ref 요소가 들어오고, 로딩 중이 아닐 때 추가 데이터 로드
        if (inView && !loading && hasMore) {
            fetchItems().then();
        }
    }, [inView]); // 의존성 배열에서 inView와 loading을 감시

    return notifications ? (
        notifications.length > 0 ? (
            <Box sx={{ width: "95vw" }}>
                <Typography gutterBottom sx={{ fontFamily: "Pretendard-Regular", fontWeight: "bold", fontSize: "4.8vw", textAlign: "left", ml: 0.5, mb: 2 }}>
                    알림 목록
                </Typography>
                <Card variant="contained" sx={{ borderRadius: "12px", marginBottom: "18px" }}>
                    <List sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}>
                        {notifications.map((notification, index) => (
                            <ButtonBase
                                component="div" // ButtonBase가 div 엘리먼트로 렌더링되게 합니다.
                                sx={{
                                    width: "100%",
                                    display: "block", // ButtonBase 내부에 Box 컴포넌트를 사용하기 위해 필요
                                    textAlign: "initial", // ButtonBase의 기본 text-align 스타일을 덮어씁니다.
                                }}
                                onClick={() => {
                                    setDetailModalOpen(true);
                                    setDetailNotificationId(notification.id);
                                    notification.read = "READ";
                                }}
                                key={index}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        bgcolor: notification.read === "UNREAD" ? "lightblue" : "inherit", // UNREAD일 경우 파란색 배경 적용
                                        display: "flex", // Flex 컨테이너로 만듭니다.
                                        flexDirection: "row", // 자식 요소들을 세로로 정렬합니다.
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* 이 Box로 ListItemAvatar를 감쌉니다. */}
                                    <ListItemAvatar sx={{ flex: 1, display: "flex", justifyContent: "center", ml: 1 }}>
                                        <Avatar>
                                            {notification.read === "UNREAD" ? (
                                                <MarkEmailUnreadOutlinedIcon sx={{ color: "black" }} />
                                            ) : (
                                                <MarkEmailReadOutlinedIcon />
                                            )}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItem sx={{ flexDirection: "column", alignItems: "flex-start", p: 1 }}>
                                        <ListItemText sx={{ mb: 0.5 }} primary={notification.title} />
                                        <ListItemText sx={{ mt: 0 }} secondary={convertToDateTime(notification.createdAt)} />
                                    </ListItem>
                                </Box>
                            </ButtonBase>
                        ))}
                        <div ref={ref}>{loading && <Loading />}</div>
                    </List>
                </Card>
                {detailNotificationId > 0 && (
                    <NotificationDetailModal id={detailNotificationId} open={detailModalOpen} handleClose={() => setDetailModalOpen(false)} />
                )}
            </Box>
        ) : (
            <Box sx={{ width: "95vw" }}>
                <Typography gutterBottom sx={{ fontFamily: "Pretendard-Regular", fontWeight: "bold", fontSize: "4.8vw", textAlign: "center" }}>
                    알림이 없습니다.
                </Typography>
            </Box>
        )
    ) : (
        <Loading />
    );
}
