import React, { useState } from "react";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import "../App.css";
import { CloseIcon, SendIcon } from "../data/icons";
import * as emailjs from "emailjs-com";
import axios from "axios";
import { useUser } from "../context/UserContext";

export default function ServiceReviewModal({ open, handleClose, openSubmitAlert }) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        bgcolor: "background.paper",
        borderRadius: 3,
        boxShadow: 24,
        p: 4,
    };

    const [review, setReview] = useState("");
    const { user, token } = useUser();

    const handleSubmit = () => {
        if (review.trim()) {
            let outcome;
            emailjs
                .send(
                    "service_2syktss",
                    "template_y0p66j5",
                    {
                        review: `userID: ${user.id} / 리뷰 내용: ${review}`,
                    },
                    "user_FRGW9AFFOhL8ApQvS3xev",
                )
                .then((r) => (outcome = r.text))
                .catch((e) => (outcome = e.text))
                .finally(() =>
                    axios.post(
                        "/api/reviews",
                        { content: review, emailSendResult: outcome },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    ),
                );
        }
        setReview("");
        handleClose();
        openSubmitAlert();
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                        width: 40,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <TextField
                    sx={{ mt: 2 }}
                    label="서비스에 대한 자유로운 의견 부탁드립니다!"
                    multiline
                    rows={8}
                    variant="filled"
                    fullWidth
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                />
                <Box sx={{ textAlign: "right" }}>
                    <Button sx={{ mt: 2 }} variant="contained" startIcon={<SendIcon />} onClick={handleSubmit}>
                        제출
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}
