import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AdminPasswordInput from "../../components/admin/AdminPasswordInput";
import AdminUsers from "../../components/admin/AdminUsers";
import AdminNotificationMessages from "../../components/admin/AdminNotificationMessages";
import { Divider } from "@mui/material";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

export default function AdminHome() {
    const [password, setPassword] = useState("");
    const [menu, setMenu] = useState("users");

    /**
     * (오늘 날짜+hour)을 해시화하여 반환
     * hour 넘어가면 해시값 리프래쉬
     */
    const getHash = () => {
        const today = new Date();
        const dateString = today.toISOString().split("T")[0]; // 'YYYY-MM-DD' 형식으로 날짜 문자열 생성
        const dateTimeString = `${dateString}-${today.getHours()}`; // 날짜와 시간을 결합
        return CryptoJS.SHA256(dateTimeString).toString();
    };

    const isPasswordCorrect = () => {
        if (password === "1013") {
            Cookies.set("admin", getHash(), { expires: 30 / 1440 }); // 30분 만료
            return true;
        }
    };

    const menus = [
        { title: "회원", value: "users" },
        { title: "알림", value: "notificationMessages" },
    ];

    return isPasswordCorrect() || Cookies.get("admin") === getHash() ? (
        <Box id="admin-home" sx={{ display: "flex", width: "100vw", p: 0 }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: "60px" }}>
                <Toolbar sx={{}}>
                    <Typography variant="h6" noWrap component="div">
                        데일리비서 어드민
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: "80px",
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: "80px", boxSizing: "border-box" },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: "auto" }}>
                    <List>
                        {menus.map((menu, index) => (
                            <Box key={index}>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => setMenu(menu.value)} sx={{ height: "100px" }}>
                                        <ListItemText primary={menu.title} sx={{ textAlign: "center" }} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </Box>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                {menu === "users" && <AdminUsers />}
                {menu === "notificationMessages" && <AdminNotificationMessages />}
            </Box>
        </Box>
    ) : (
        <AdminPasswordInput password={password} setPassword={setPassword} />
    );
}
