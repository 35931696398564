import React, { useCallback, useEffect, useState } from "react";

const todayFortuneInit = {
    calendarType: "SOLAR",
    birthDate: "1995-10-13",
    birthTime: "",
    gender: "",
};

export const useServiceSetting = (user) => {
    const [checkTodayFortune, setCheckTodayFortune] = React.useState(false);
    const [checkWeather, setCheckWeather] = React.useState(false);
    const [checkNews, setCheckNews] = React.useState(false);
    const [address, setAddress] = useState("");
    const [notificationTime, setNotificationTime] = useState("08:00");
    const [todayFortune, setTodayFortune] = useState(todayFortuneInit);
    const [daumPostcodeModalOpen, setDaumPostcodeModalOpen] = useState(false);
    const [validationAlertOpen, setValidationAlertOpen] = useState(false);
    const [validationMessages, setValidationMessages] = useState([]);

    const existUserService = useCallback(
        (serviceType) => {
            if (!user) return false;
            return user.services.some((service) => service.type === serviceType);
        },
        [user],
    );

    useEffect(() => {
        if (!user) return;

        setCheckTodayFortune(existUserService("TODAY_FORTUNE"));
        setCheckWeather(existUserService("WEATHER"));
        setCheckNews(existUserService("NEWS"));
        setTodayFortune(existUserService("TODAY_FORTUNE") ? user.services.find((service) => service.type === "TODAY_FORTUNE") : todayFortuneInit);
        setAddress(existUserService("WEATHER") ? user.services.find((service) => service.type === "WEATHER").address : "");
        setNotificationTime(user?.notificationTimes.length > 0 ? user.notificationTimes[0] : "08:00");
    }, [user, existUserService]);

    return {
        checkTodayFortune,
        setCheckTodayFortune,
        checkWeather,
        setCheckWeather,
        checkNews,
        setCheckNews,
        validationAlertOpen,
        setValidationAlertOpen,
        validationMessages,
        setValidationMessages,
        daumPostcodeModalOpen,
        setDaumPostcodeModalOpen,
        address,
        setAddress,
        notificationTime,
        setNotificationTime,
        todayFortune,
        setTodayFortune,
    };
};
