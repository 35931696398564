import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ServicesSetting from "../components/ServicesSetting";
import { Box } from "@mui/material";
import { useLocalStorage } from "../context/LocalStorageContext";
import { useUser } from "../context/UserContext";
import Loading from "../components/Loading";

function LoginSuccess() {
    const { updateToken } = useLocalStorage();
    const { search } = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(search);
    const tokenQuery = queryParams.get("token");

    if (tokenQuery) updateToken(tokenQuery);
    else navigate("/login-failed");

    const { user, isLoading } = useUser();

    // 로컬 스토리지에 토큰을 확인하여, 로그인 안 된 상태면 인트로 페이지로 넘긴다.
    useEffect(() => {
        if (isLoading) return; // 로딩 중이면 아래 로직 수행하지 않음

        if (user?.notificationTimes.length > 0 && user?.services.length > 0) navigate("/");
    }, [navigate, user, isLoading]);

    return user ? (
        <Box>
            <ServicesSetting user={user} />
        </Box>
    ) : (
        <Loading />
    );
}

export default LoginSuccess;
