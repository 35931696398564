const timeOptions = [
    { label: "자 (23:30 ~ 01:29)", value: "RAT" },
    { label: "축 (01:30 ~ 03:29)", value: "OX" },
    { label: "인 (03:30 ~ 05:29)", value: "TIGER" },
    { label: "묘 (05:30 ~ 07:29)", value: "RABBIT" },
    { label: "진 (07:30 ~ 09:29)", value: "DRAGON" },
    { label: "사 (09:30 ~ 11:29)", value: "SNAKE" },
    { label: "오 (11:30 ~ 13:29)", value: "HORSE" },
    { label: "미 (13:30 ~ 15:29)", value: "SHEEP" },
    { label: "신 (15:30 ~ 17:29)", value: "MONKEY" },
    { label: "유 (17:30 ~ 19:29)", value: "ROOSTER" },
    { label: "술 (19:30 ~ 21:29)", value: "DOG" },
    { label: "해 (21:30 ~ 23:29)", value: "PIG" },
];

export default timeOptions;
