import { WaterDropOutlined } from "@mui/icons-material";
import { OpacityIcon, WaterDropIcon } from "../../../data/icons";
import Typography from "@mui/material/Typography";
import WeatherImage from "./WeatherImage";
import { Box } from "@mui/material";
import React from "react";

export default function WeeklyWeatherItem({ rainfall, skyStatus, skyStatusImageUrl }) {
    const getImageSrc = () => {
        switch (skyStatus) {
            case "WT1":
                return "/weather/day_none_sunny.png";
            case "WT2":
                return "/weather/day_none_sunny.png";
            case "WT5":
                return "/weather/day_none_cloud.png";
            case "WT7":
                return "/weather/common_none_overcast.png";
            case "WT9":
                return "/weather/common_rain_overcast.png";
            case "WT12":
                return "/weather/common_snow_overcast.png";
            case "WT22":
                return "/weather/day_rain_sunny_and_cloud.png";
            default:
                return skyStatusImageUrl;
        }
    };

    return (
        <Box sx={{ display: "flex", alignItems: "center", width: 80, flexDirection: "row" }}>
            {parseFloat(rainfall) < 20 ? (
                <WaterDropOutlined sx={{ m: 0, fontSize: 16, color: "grey" }} />
            ) : parseFloat(rainfall) >= 70 ? (
                <WaterDropIcon sx={{ m: 0, fontSize: 16, color: "grey" }} />
            ) : (
                <OpacityIcon sx={{ m: 0, fontSize: 16, color: "grey" }} />
            )}
            <Typography component="p" sx={{ m: 0, ml: -0.2, fontSize: 13, width: 23 }}>
                {rainfall}
            </Typography>
            <WeatherImage src={getImageSrc()} size={35} mt={0} />
        </Box>
    );
}
