import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Divider, IconButton, Modal } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useLocalStorage } from "../../context/LocalStorageContext";
import TodayFortuneNotificationDetail from "./fortune/TodayFortuneNotificationDetail";
import WeatherNotificationDetail from "./weather/WeatherNotificationDetail";
import NewsNotificationDetail from "./NewsNotificationDetail";
import { CloseIcon } from "../../data/icons";
import FixedLoading from "../FixedLoading";

export default function NotificationDetailModal({ id, open, handleClose }) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "86%",
        maxHeight: "80%", // 높이 설정
        bgcolor: "background.paper",
        borderRadius: 3,
        boxShadow: 24,
        p: 1,
        overflowY: "auto", // 세로 스크롤 적용,
    };

    const [notification, setNotification] = useState(false);
    const { token } = useLocalStorage();

    useEffect(() => {
        axios
            .get(`/api/notification-messages/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => setNotification(response.data.content))
            .catch((error) => {});
    }, [token, id]);

    const renderDetails = () => {
        return notification.details.map((detail, index) => {
            switch (detail.serviceType) {
                case "TODAY_FORTUNE":
                    return (
                        <Box key={index}>
                            <TodayFortuneNotificationDetail todayFortuneJson={detail.content} />
                            <Divider sx={{ mt: 1, mb: 1 }} />
                        </Box>
                    );
                case "WEATHER":
                    return (
                        <Box key={index}>
                            <WeatherNotificationDetail weatherJson={detail.content} />
                            <Divider sx={{ mt: 1, mb: 1 }} />
                        </Box>
                    );
                case "NEWS":
                    return (
                        <Box key={index}>
                            <NewsNotificationDetail newsJson={detail.content} />
                            <Divider sx={{ mt: 1, mb: 1 }} />
                        </Box>
                    );
                default:
                    return null;
            }
        });
    };

    return notification ? (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Box>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            top: 1,
                            right: 1,
                            width: 40,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Card variant="contained" sx={{ borderRadius: "12px", textAlign: "left" }}>
                        <Typography
                            gutterBottom
                            sx={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "5.5vw",
                                margin: 3,
                                marginBottom: 0,
                                fontFamily: "Pretendard-Regular",
                            }}
                        >
                            {notification.title}
                        </Typography>
                        <CardContent sx={{ m: 1, pt: 1, fontSize: "4vw" }}>
                            <Typography gutterBottom sx={{ fontSize: "4vw", fontFamily: "Pretendard-Regular" }}>
                                {notification.header}
                            </Typography>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            {renderDetails()}
                            <Typography gutterBottom sx={{ fontSize: "4vw", fontFamily: "Pretendard-Regular" }}>
                                {notification.footer}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Modal>
    ) : (
        <FixedLoading />
    );
}
