import Typography from "@mui/material/Typography";
import { findValueByCategory, getColorByPmValue, renderWeatherImage } from "../../../utils/weatherUtils";
import { Box } from "@mui/material";
import React from "react";

export default function WeatherSummary({ weatherItems, airQuality }) {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
            }}
        >
            {renderWeatherImage(weatherItems, 60)}
            <Typography
                gutterBottom
                sx={{
                    m: 0,
                    ml: 1,
                    fontSize: "32px",
                    fontFamily: "Pretendard-Regular",
                    display: "flex",
                    alignItems: "flex-start", // 상단선에 맞춰 정렬
                }}
            >
                <span>{findValueByCategory(weatherItems, "TMP")}</span>
                <small style={{ fontSize: "20px", marginTop: "5px" }}>°C</small>
            </Typography>

            <Box sx={{ ml: 1 }}>
                <Typography gutterBottom sx={{ m: 0, ml: 1, fontSize: "14.5px", fontFamily: "Pretendard-Regular" }}>
                    강수확률:{" "}
                    <span style={{ color: findValueByCategory(weatherItems, "POP") >= 60 ? "red" : "inherit", fontWeight: "bold" }}>
                        {findValueByCategory(weatherItems, "POP")}%
                    </span>
                </Typography>
                <Typography gutterBottom sx={{ m: 0, ml: 1, fontSize: "14.5px", fontFamily: "Pretendard-Regular" }}>
                    습도:{" "}
                    <span style={{ color: findValueByCategory(weatherItems, "REH") >= 80 ? "red" : "inherit", fontWeight: "bold" }}>
                        {findValueByCategory(weatherItems, "REH")}%
                    </span>
                </Typography>
                <Typography gutterBottom sx={{ m: 0, ml: 1, fontSize: "14.5px", fontFamily: "Pretendard-Regular" }}>
                    풍속:{" "}
                    <span style={{ color: findValueByCategory(weatherItems, "WSD") >= 5.5 ? "red" : "inherit", fontWeight: "bold" }}>
                        {findValueByCategory(weatherItems, "WSD")}m/s
                    </span>
                </Typography>
                <Typography gutterBottom sx={{ m: 0, ml: 1, fontSize: "14.5px", fontFamily: "Pretendard-Regular" }}>
                    미세먼지:{" "}
                    <span style={{ color: getColorByPmValue(airQuality.pm10Grade), fontWeight: "bold" }}>
                        {airQuality.pm10GradeDescription} | {airQuality.pm10Value ?? "-"}
                    </span>
                </Typography>
                <Typography gutterBottom sx={{ m: 0, ml: 1, fontSize: "14.5px", fontFamily: "Pretendard-Regular" }}>
                    초미세먼지:{" "}
                    <span style={{ color: getColorByPmValue(airQuality.pm25Grade), fontWeight: "bold" }}>
                        {airQuality.pm25GradeDescription} | {airQuality.pm25Value ?? "-"}
                    </span>
                </Typography>
            </Box>
        </Box>
    );
}
