import React, { useState } from "react";
import { Box, Button, Card, IconButton, keyframes } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styled from "@emotion/styled";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ToDoListItem from "./ToDoListItem";
import ToDoListTitle from "./ToDoListTitle";
import { MenuIcon } from "../../data/icons";
import ToDoListInputModal from "./ToDoListInputModal";

// 잔상 효과 애니메이션 정의
const pulse = keyframes`
    0% {
        transform: scale(1);
        box-shadow: 0 0 0px rgba(128, 128, 128, 0.7); // 아이콘 크기에서 시작
    }
    70% {
        transform: scale(1.2);
        box-shadow: 0 0 0px rgba(128, 128, 128, 0); // 커지는 효과
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0px rgba(128, 128, 128, 0); // 원래 크기로 돌아옴
    }
`;

// 애니메이션을 적용한 IconButton 스타일링
const AnimatedIconButton = styled(IconButton)`
    animation: ${pulse} 1.5s infinite;
`;

export default function ToDoList() {
    const initialData = [
        {
            id: "1",
            title: "테스트 할 일1 \n 안녕하세요 긴 텍스트 테스트입니다.",
            completed: false,
            createdAt: "2024-08-13 23:00:16",
        },
        {
            id: "2",
            title: "테스트 할 일2 줄바꿈없이 긴텍스트를 입력해볼게요,",
            completed: false,
            createdAt: "2024-08-10 23:00:16",
        },
        {
            id: "3",
            title: "테스트 할 일3",
            completed: false,
            createdAt: "2024-08-01 23:00:16",
        },
    ];

    const [inputModalOpen, setInputModalOpen] = useState(false);
    const [data, setData] = useState(initialData);
    const [draggable, setDraggable] = useState(false);

    const handleCheckboxChange = (id) => {
        setData((prevData) => prevData.map((item) => (item.id === id ? { ...item, completed: !item.completed } : item)));
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(data);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setData(items);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid black",
                borderRadius: 1,
                padding: 1,
                m: 1,
                position: "relative",
            }}
        >
            <ToDoListTitle />

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <Box {...provided.droppableProps} ref={provided.innerRef} sx={{ display: "flex", flexDirection: "column", mt: "3.5vw" }}>
                            {data.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!draggable}>
                                    {(provided) => (
                                        <Card
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            variant="outlined"
                                            sx={{ p: 1, mb: 1, backgroundColor: "#fafafa", display: "flex", alignItems: "center", borderRadius: "12px" }}
                                        >
                                            <ToDoListItem item={item} handleCheckboxChange={handleCheckboxChange} />
                                            {/* 햄버거 드래그 버튼 - 특정 조건에서만 보임 */}
                                            {draggable && (
                                                <Box {...provided.dragHandleProps} sx={{ cursor: "grab" }}>
                                                    <AnimatedIconButton>
                                                        <MenuIcon style={{ color: "grey" }} />
                                                    </AnimatedIconButton>
                                                </Box>
                                            )}
                                        </Card>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>

            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Button sx={{ color: "grey" }} onClick={() => setDraggable(!draggable)}>
                    {draggable ? "완료" : "순서 편집"}
                </Button>
                <AnimatedIconButton onClick={() => setInputModalOpen(true)}>
                    <AddCircleIcon style={{ color: "grey", fontSize: "35px" }} />
                </AnimatedIconButton>
            </Box>
            <ToDoListInputModal open={inputModalOpen} handleClose={() => setInputModalOpen(false)} />
        </Box>
    );
}
