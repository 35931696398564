import React from "react";
import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ChecklistIcon from "@mui/icons-material/Checklist";

export default function ToDoListTitle() {
    return (
        <ListItem
            sx={{
                position: "absolute",
                top: "-15px",
                left: "10px",
                backgroundColor: "white",
                padding: "0 5px",
                width: "fit-content",
                ml: "19px",
            }}
        >
            <ListItemIcon>
                <ChecklistIcon style={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary={<Typography sx={{ fontFamily: "SUITE-Regular", fontSize: "4.5vw" }}>할 일 관리</Typography>} />
        </ListItem>
    );
}
