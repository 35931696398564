import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import "../App.css";
import DaumPostcodeEmbed from "react-daum-postcode";
import Typography from "@mui/material/Typography";
import { CloseIcon } from "../data/icons";

export default function DaumPostcodeModal({ open, handleClose, setAddress }) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        bgcolor: "background.paper",
        borderRadius: 3,
        boxShadow: 24,
        p: 4,
    };

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddress += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
        }
        setAddress(fullAddress);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                        width: 40,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography
                    gutterBottom
                    sx={{
                        fontFamily: "Pretendard-Regular",
                        fontSize: "4vw", // 폰트 크기 증가
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "primary.main", // 색상 변경
                        p: 1,
                    }}
                >
                    날씨 정보를 받을 위치를 입력해주세요.
                </Typography>
                <DaumPostcodeEmbed onComplete={handleComplete} style={{}} />
            </Box>
        </Modal>
    );
}
