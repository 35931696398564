import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Avatar, ButtonBase, IconButton, Menu, MenuItem } from "@mui/material";
import { MenuIcon } from "../data/icons";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../context/LocalStorageContext";
import { useUser } from "../context/UserContext";

export default function Header() {
    const { token, removeToken } = useLocalStorage();
    const { removeUser, deleteUser } = useUser();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        removeToken();
        removeUser();
        navigate("/intro");
        handleClose();
    };

    const handleDeleteUser = () => {
        removeToken();
        removeUser();
        deleteUser();
        navigate("/intro");
        handleClose();
    };

    return (
        <div className={"top-bar"}>
            {/*<NoticeModal />*/}
            <Box sx={{ flexGrow: 1 }}>
                <AppBar sx={{ backgroundColor: "rgba(252, 250, 248, 0)", boxShadow: "none" }}>
                    <Toolbar sx={{ color: "black", ml: 0, mt: 2.5, pl: 1 }}>
                        <ButtonBase
                            component="div" // ButtonBase가 div 엘리먼트로 렌더링되게 합니다.
                            sx={{
                                display: "block", // ButtonBase 내부에 Box 컴포넌트를 사용하기 위해 필요
                                textAlign: "initial", // ButtonBase의 기본 text-align 스타일을 덮어씁니다.
                            }}
                            onClick={() => navigate("/")} // Box 클릭 시 navigate 함수를 호출합니다.
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex", // Box 내부를 flex 컨테이너로 만듭니다.
                                    alignItems: "center", // 요소들을 수평 축을 기준으로 가운데 정렬합니다.
                                }}
                            >
                                <Avatar alt="icon" src="/icon.png" sx={{ width: 40, height: 40 }} />
                                <Typography variant="h6" component="div" sx={{ fontFamily: "GangwonEdu_OTFBoldA" }}>
                                    데일리비서
                                </Typography>
                            </Box>
                        </ButtonBase>
                        <Box sx={{ flexGrow: 1 }} /> {/* 여기에 추가된 Box */}
                        {token && (
                            <div>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem sx={{ letterSpacing: 1.5 }} onClick={handleLogout}>
                                        로그아웃
                                    </MenuItem>
                                    <MenuItem sx={{ letterSpacing: 1.5 }} onClick={handleDeleteUser}>
                                        회원탈퇴
                                    </MenuItem>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    );
}
