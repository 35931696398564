import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import TodayFortuneNotificationDetail from "../notifications/fortune/TodayFortuneNotificationDetail";
import { CloseIcon } from "../../data/icons";

export default function TodayFortuneModal({ open, handleClose, todayFortuneJson }) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        maxHeight: "51%", // 높이 설정
        bgcolor: "background.paper",
        borderRadius: 3,
        boxShadow: 24,
        p: 4,
        overflowY: "auto", // 세로 스크롤 적용,
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                        width: 40,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <TodayFortuneNotificationDetail todayFortuneJson={todayFortuneJson} />
            </Box>
        </Modal>
    );
}
