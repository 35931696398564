import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import { convertToDateTimeWithoutYear } from "../../utils/dateUtils";
import Loading from "../Loading";
import { TablePaginationActions } from "@mui/base";
import AdminDailyReadableNotificationMessagesChart from "./AdminDailyReadableNotificationMessagesChart";

export default function AdminNotificationMessages() {
    const [messages, setMessages] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    useEffect(() => {
        axios.get(`/api/notification-messages?page=${page}&size=${rowsPerPage}`).then((response) => {
            setMessages(response.data.content);
        });
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return messages.length > 0 ? (
        <Box id={"admin-notification-messages"}>
            <AdminDailyReadableNotificationMessagesChart />
            <TableContainer component={Paper} sx={{}} style={{ overflow: "auto" }}>
                <Table sx={{ width: "100%" }} stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>no</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>baseDate</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>read</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>nickname</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>userNote</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>modifiedAt</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {messages.map((message, index) => (
                            <TableRow key={message.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{message.baseDate}</TableCell>
                                <TableCell>{message.read === "READ" ? "O" : "X"}</TableCell>
                                <TableCell>{message.userNickname ?? "-"}</TableCell>
                                <TableCell>{message.userNote ?? "-"}</TableCell>
                                <TableCell>{convertToDateTimeWithoutYear(message.modifiedAt)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[50, 100, 150, 200, { label: "All", value: 99999999 }]}
                                colSpan={3}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        inputProps: {
                                            "aria-label": "rows per page",
                                        },
                                        native: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    ) : (
        <Loading />
    );
}
