import React, { useState } from "react";
import { Box, Button, Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { PriorityHighIcon } from "../data/icons";
import axios from "axios";

function LoginFailed() {
    const [expoPushToken] = useState(localStorage.getItem("expoPushToken") || "");

    /**
     * 카카오 로그인 버튼 클릭 시 로그인 처리
     */
    const handleKakaoLogin = () => {
        axios
            .get(`/api/kakao/oauth/authorize?state=${expoPushToken}`)
            .then((response) => {
                window.location.href = response.data;
            })
            .catch((error) => {});
    };

    return (
        <Box sx={{ width: "95vw" }}>
            <Card variant="contained" sx={{ borderRadius: "12px", marginBottom: "18px" }}>
                <CardContent sx={{ margin: "10px", padding: "16px", "&:last-child": { paddingBottom: "16px" } }}>
                    <PriorityHighIcon sx={{ fontSize: "30vw", mb: 2 }} />
                    <Typography sx={{ fontSize: "4.8vw", fontFamily: "Cafe24Supermagic-Bold-v1.0" }}>
                        로그인에 실패하였습니다. 다시 로그인을 시도해주세요!
                    </Typography>
                    <Button
                        variant="contained"
                        style={{
                            borderRadius: "12px",
                            backgroundColor: "RGB(246, 226, 75)",
                            color: "black",
                            fontFamily: "Cafe24Supermagic-Bold-v1.0",
                            fontSize: "4vw",
                            margin: "20px",
                        }}
                        onClick={handleKakaoLogin}
                    >
                        카카오 로그인하기
                    </Button>
                </CardContent>
            </Card>
        </Box>
    );
}

export default LoginFailed;
