export const convertTo12HourFormat = (time) => {
    if (!time) return "";

    const [hour, minute] = time.split(":").map(Number);
    const ampm = hour >= 12 ? "오후" : "오전";
    const convertedHour = hour % 12 || 12; // 0시는 12시로 변환

    // 분이 00이 아니면 시간에 분을 추가
    const displayMinute = minute !== 0 ? `${minute}분` : "";
    return `${ampm} ${convertedHour}시 ${displayMinute}`.trim();
};

export const convertToDateTime = (isoString) => {
    if (!isoString) return;
    const date = new Date(isoString);
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };
    return new Intl.DateTimeFormat("ko-KR", options).format(date);
};

export const convertToDateTimeWithoutYear = (isoString) => {
    if (!isoString) return;
    const date = new Date(isoString);
    const options = {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };
    return new Intl.DateTimeFormat("ko-KR", options).format(date);
};

export const convertToDateTimeWithoutYearAndTime = (isoString) => {
    if (!isoString) return;
    const date = new Date(isoString);
    const options = {
        month: "long",
        day: "numeric",
    };
    return new Intl.DateTimeFormat("ko-KR", options).format(date);
};

// 06.21. 형태로 반환
export const convertToDateTimeMMDD = (isoString) => {
    if (!isoString) return;
    const date = new Date(isoString);
    const options = {
        month: "2-digit",
        day: "2-digit",
    };
    const formattedDate = new Intl.DateTimeFormat("ko-KR", options).format(date);

    // "MM. DD" 형태로 반환된 것을 "MM.DD" 형태로 변환
    return formattedDate.replace(" ", "");
};

export const convertToDate = (dateString) => {
    const dateObj = new Date(dateString);

    // 옵션 설정 (요일 추가)
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long", // 요일 추가
    };

    // 지역화된 날짜 문자열을 반환
    return dateObj.toLocaleDateString("ko-KR", options);
};

export const convertTo24HourFormat = (date, time) => {
    const [year, month, day] = date.split("-").map(Number);
    const [hour, minute] = time.split(":").map(Number);

    const now = new Date();
    const currentDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const targetDateTime = new Date(year, month - 1, day);

    const diffDay = (targetDateTime - currentDateTime) / (1000 * 3600 * 24);

    if (hour === 0 && minute === 0) {
        if (diffDay === 0) return "0시";
        if (diffDay === 1) return "내일";
        if (diffDay === 2) return "모레";
        if (diffDay === 3) return "글피";
        else return `${month}.${day}`;
    } else {
        return `${hour}시`;
    }
};
