import React, { useEffect } from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { getIcon } from "../utils/iconUtils";
import { services } from "../data/services";
import axios from "axios";
import { useLocalStorage } from "../context/LocalStorageContext";
import { useNavigate } from "react-router-dom";
import { ErrorOutlineIcon, PersonIcon } from "../data/icons";
import FixedLoading from "../components/FixedLoading";

export default function Intro() {
    const { expoPushToken, token, updateToken } = useLocalStorage();
    const navigate = useNavigate();
    const [noLoginConfirmDialogOpen, setNoLoginConfirmDialogOpen] = React.useState(false);
    const [loginPageLoading, setLoginPageLoading] = React.useState(false);

    /**
     * 이미 로그인 토큰 있을 시, 홈으로 이동
     */
    useEffect(() => {
        if (token) {
            navigate("/");
        }
    }, [token, navigate]);

    /**
     * 카카오 로그인 버튼 클릭 시 로그인 처리
     */
    const handleKakaoLogin = () => {
        setLoginPageLoading(true);

        axios
            .get(`/api/kakao/oauth/authorize?state=${expoPushToken}`)
            .then((response) => {
                window.location.href = response.data;
            })
            .finally(() => {
                setLoginPageLoading(false);
            });
    };

    const handleNaverLogin = () => {
        setLoginPageLoading(true);

        axios
            .get(`/api/naver/oauth/authorize?state=${expoPushToken}`)
            .then((response) => {
                window.location.href = response.data;
            })
            .finally(() => {
                setLoginPageLoading(false);
            });
    };

    const handleAppleLogin = () => {
        setLoginPageLoading(true);

        axios
            .get(`/api/apple/oauth/authorize?state=${expoPushToken}`)
            .then((response) => {
                window.location.href = response.data;
            })
            .finally(() => {
                setLoginPageLoading(false);
            });
    };

    const handleNoLogin = () => {
        setLoginPageLoading(true);

        axios
            .post("/api/users/guest-login", {
                expoPushToken: expoPushToken,
                loginType: "GUEST",
            })
            .then((response) => {
                updateToken(response.data.content);
                navigate("/login-success?token=" + response.data.content);
            })
            .finally(() => {
                setLoginPageLoading(false);
            });
    };

    // 서비스 목록은 디비에서 받아오거나해서 foreach로 돌리고, 오픈 예정인 서비스는 따로 넣자.
    return (
        <Box sx={{ width: "95vw" }}>
            <Card variant="contained" sx={{ borderRadius: "12px", marginBottom: "18px" }}>
                <CardContent sx={{ margin: "10px", padding: "10px", "&:last-child": { paddingBottom: "10px" } }}>
                    <Typography gutterBottom sx={{ fontFamily: "Orbit-Regular", fontWeight: "bold", fontSize: "4.5vw" }}>
                        하루에 필요한 모든 것을 한 곳에서 만나보세요.
                    </Typography>
                </CardContent>
            </Card>
            <Card variant="contained" sx={{ borderRadius: "12px" }}>
                <CardContent sx={{ margin: "10px", padding: "10px", "&:last-child": { paddingBottom: "0px" } }}>
                    <Typography gutterBottom sx={{ fontFamily: "Orbit-Regular", fontWeight: "bold", fontSize: "4.6vw" }}>
                        데일리비서는 아래 서비스를 제공합니다.
                    </Typography>
                    <List>
                        {services.map((service, index) => (
                            <ListItem key={index}>
                                <ListItemIcon>{getIcon(service.type)}</ListItemIcon>
                                <ListItemText primary={<Typography sx={{ fontFamily: "SUITE-Regular", fontSize: "4.6vw" }}>{service.name}</Typography>} />
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Card>
            <Card variant="contained" sx={{ borderRadius: "12px", marginTop: "18px" }}>
                <CardContent sx={{ margin: "10px", padding: "10px", "&:last-child": { paddingBottom: "10px" } }}>
                    <Typography gutterBottom sx={{ fontFamily: "Orbit-Regular", fontWeight: "bold", fontSize: "4.3vw" }}>
                        맞춤형 정보를 정해진 시간에 제공하여 일상 루틴을 간편하게 만들어드립니다.
                    </Typography>
                </CardContent>
            </Card>
            {!expoPushToken && (
                <Card variant="contained" sx={{ borderRadius: "12px", marginTop: "18px" }}>
                    <CardContent sx={{ margin: "5px", padding: "10px", "&:last-child": { paddingBottom: "10px" } }}>
                        <Typography gutterBottom sx={{ fontFamily: "Orbit-Regular", fontWeight: "bold", fontSize: "3.5vw" }}>
                            알림 기능은 데일리비서 핵심 기능입니다!
                        </Typography>
                        <Typography gutterBottom sx={{ fontFamily: "Orbit-Regular", fontWeight: "bold", fontSize: "3.5vw" }}>
                            <span style={{ color: "red", fontWeight: "bold" }}>알림 권한</span>을 꼭 허용해주세요.
                        </Typography>
                    </CardContent>
                </Card>
            )}
            <Box sx={{ marginTop: "10px", marginBottom: "10px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Button
                    variant="contained"
                    sx={{
                        mb: 1,
                        width: "75vw",
                        height: 45,
                        backgroundColor: "#FEE500",
                        color: "black",
                        fontWeight: 500,
                        "&:hover": {
                            backgroundColor: "#FEE500", // 버튼 hover 상태의 배경색
                        },
                        "&:active": {
                            backgroundColor: "#FEE500", // 버튼 active 상태의 배경색
                        },
                        boxShadow: 0,
                    }}
                    startIcon={<Avatar src="/kakao_icon.png" />}
                    onClick={handleKakaoLogin}
                >
                    카카오로 계속하기
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        mb: 1,
                        width: "75vw",
                        height: 45,
                        backgroundColor: "#03C75A",
                        color: "white",
                        fontWeight: 500,
                        "&:hover": {
                            backgroundColor: "#03C75A", // 버튼 hover 상태의 배경색
                        },
                        "&:active": {
                            backgroundColor: "#03C75A", // 버튼 active 상태의 배경색
                        },
                        boxShadow: 0,
                    }}
                    startIcon={<Avatar src="/naver_icon.png" />}
                    onClick={handleNaverLogin}
                >
                    네이버로 계속하기
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        mb: 1,
                        width: "75vw",
                        height: 45,
                        backgroundColor: "white",
                        color: "black",
                        fontWeight: 500,
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "white", // 버튼 hover 상태의 배경색
                        },
                        "&:active": {
                            backgroundColor: "white", // 버튼 active 상태의 배경색
                        },
                        boxShadow: 0,
                    }}
                    startIcon={<Avatar src="/apple_icon.png" />}
                    onClick={handleAppleLogin}
                >
                    Apple로 계속하기
                </Button>
                <Button
                    variant="contained"
                    onClick={() => setNoLoginConfirmDialogOpen(true)}
                    startIcon={
                        <Avatar sx={{ bgcolor: "transparent" }}>
                            <PersonIcon />
                        </Avatar>
                    }
                    sx={{ height: 45, width: "75vw", fontWeight: "500", boxShadow: 0 }}
                >
                    게스트로 계속하기
                </Button>
            </Box>
            <Dialog open={noLoginConfirmDialogOpen} onClose={() => setNoLoginConfirmDialogOpen(false)} aria-labelledby="draggable-dialog-title">
                <DialogTitle style={{ cursor: "move", display: "flex", alignItems: "center" }} id="draggable-dialog-title">
                    <ErrorOutlineIcon style={{ color: "red" }} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ fontFamily: "Pretendard-Regular" }}>
                        설정하신 정보나 사용 이력이 저장되지 않습니다. 그래도 게스트로 계속하시겠습니까?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pt: 0 }}>
                    <Button autoFocus onClick={() => setNoLoginConfirmDialogOpen(false)}>
                        취소
                    </Button>
                    <Button onClick={handleNoLogin}>계속하기</Button>
                </DialogActions>
            </Dialog>
            {loginPageLoading && <FixedLoading />}
        </Box>
    );
}
