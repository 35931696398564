import React from "react";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { getColorByPmValue } from "../../../utils/weatherUtils";

export default function AirQuality({ airQuality }) {
    return (
        <Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box sx={{ mt: 2.5, mb: 0, display: "flex", flexDirection: "row", gap: 4, alignItems: "center" }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography gutterBottom sx={{ fontSize: "0.9375rem", fontWeight: "bold", fontFamily: "Pretendard-Regular" }}>
                            미세먼지
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", mt: -1 }}>
                            <Typography
                                sx={{
                                    fontSize: "1.75rem",
                                    fontWeight: "bold",
                                    fontFamily: "Pretendard-Regular",
                                    color: getColorByPmValue(airQuality.pm10Grade), // 색상 적용
                                }}
                            >
                                {airQuality.pm10Value ?? "-"}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "0.9375rem",
                                    fontWeight: "bold",
                                    fontFamily: "Pretendard-Regular",
                                    color: getColorByPmValue(airQuality.pm10Grade), // 색상 적용
                                    ml: 1, // 약간의 왼쪽 마진 추가
                                }}
                            >
                                {airQuality.pm10GradeDescription ?? "-"}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Divider orientation="vertical" flexItem sx={{ height: 45, backgroundColor: "grey.400" }} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                            gutterBottom
                            sx={{
                                fontSize: "0.9375rem",
                                fontWeight: "bold",
                                fontFamily: "Pretendard-Regular",
                            }}
                        >
                            초미세먼지
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", mt: -1 }}>
                            <Typography
                                sx={{
                                    fontSize: "1.75rem",
                                    fontWeight: "bold",
                                    fontFamily: "Pretendard-Regular",
                                    color: getColorByPmValue(airQuality.pm25Grade), // 색상 적용
                                }}
                            >
                                {airQuality.pm25Value ?? "-"}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "0.9375rem",
                                    fontWeight: "bold",
                                    fontFamily: "Pretendard-Regular",
                                    color: getColorByPmValue(airQuality.pm25Grade), // 색상 적용
                                    ml: 1, // 약간의 왼쪽 마진 추가
                                }}
                            >
                                {airQuality.pm25GradeDescription ?? "-"}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                        gutterBottom
                        sx={{
                            fontSize: "0.875rem",
                            fontFamily: "Pretendard-Regular",
                        }}
                    >
                        업데이트: {airQuality.dataTime?.slice(5) ?? "-"} | 측정소: {airQuality.stationName ?? "-"}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
