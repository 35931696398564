import React, { useEffect, useState } from "react";
import axios from "axios";
import ApexCharts from "react-apexcharts";
import { convertToDateTimeMMDD } from "../../utils/dateUtils";
import { Box, Button } from "@mui/material";

export default function AdminUserStatisticsChart() {
    const [statistics, setStatistics] = useState([]);
    const [page, setPage] = useState(0);
    const [itemsPerPage] = useState(25);

    useEffect(() => {
        axios.get(`/api/users/statistics?page=${page}&size=${itemsPerPage}`).then((response) => {
            setStatistics(response.data.content.reverse());
        });
    }, [page, itemsPerPage]);

    const handlePreviousPage = () => {
        setPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const handleNextPage = () => {
        setPage(page + 1);
    };

    // 데이터 변환
    const dates = statistics.map((item) => convertToDateTimeMMDD(item.baseDate));
    const dailyIncrementCounts = statistics.map((item) => item.dailyIncrementCount);
    const totalCounts = statistics.map((item) => item.totalCount);

    const options = {
        chart: {
            type: "line",
            height: 350,
            toolbar: {
                show: true,
            },
            zoom: {
                enabled: false,
            },
            background: "#ffffff", // 차트 배경색 설정
        },
        stroke: {
            curve: "smooth",
            width: 2,
        },
        title: {
            text: "일별 회원 수 증가 추이 / 누적 회원 수 추이",
            align: "center",
            style: {
                fontSize: "20px",
                fontWeight: "bold",
                color: "#263238",
            },
        },
        dataLabels: {
            enabled: true, // 데이터 레이블 활성화
            offsetY: -6, // 레이블을 데이터 포인트 위로 조금 올리기
            background: "none", // 배경 제거
            style: {
                colors: ["black"], // 글자 색상을 검정색으로 변경
                fontSize: "15px", // 레이블의 글자 크기 설정
                fontWeight: 500,
                fontFamily: "Pretendard-Regular",
            },
        },
        markers: {
            size: 4,
            hover: {
                size: 6,
            },
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -20,
        },
        labels: dates,
        xaxis: {
            labels: {
                style: {
                    fontSize: "12px",
                },
            },
            tickAmount: 30, // 세로 눈금의 개수 설정
        },
        yaxis: [
            {
                title: {
                    text: "일별 회원 증가 수",
                    style: {
                        fontSize: "14px",
                    },
                },
                labels: {
                    style: {
                        fontSize: "12px",
                    },
                },
            },
            {
                opposite: true,
                title: {
                    text: "누적 회원 수",
                    style: {
                        fontSize: "14px",
                    },
                },
                labels: {
                    style: {
                        fontSize: "12px",
                    },
                },
            },
        ],
        grid: {
            borderColor: "#f1f1f1",
            xaxis: {
                lines: {
                    show: true, // 세로 눈금 표시
                },
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        colors: ["#FF5733", "#33C3FF"], // 데이터 시리즈 색상 설정 (변경 가능)
    };

    const series = [
        {
            name: "일별 회원 증가 수",
            type: "line",
            data: dailyIncrementCounts,
        },
        {
            name: "누적 회원 수",
            type: "line",
            data: totalCounts,
        },
    ];

    return (
        <Box>
            <ApexCharts options={options} series={series} type="line" height={350} />
            <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                <Button variant="contained" onClick={handleNextPage}>
                    이전
                </Button>
                <Button variant="contained" onClick={handlePreviousPage} disabled={page === 0}>
                    다음
                </Button>
            </Box>
        </Box>
    );
}
