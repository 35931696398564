import WeatherImage from "../components/notifications/weather/WeatherImage";
import React from "react";

export const findValueByCategory = (items, category) => {
    return items.find((item) => item.category === category).value;
};

export const getDressCode = (temperature) => {
    if (temperature >= 28) {
        return "28°C 이상: 민소매, 반팔, 반바지, 원피스";
    } else if (temperature >= 23) {
        return "23°C~27°C: 반팔, 얇은 셔츠, 반바지, 면바지";
    } else if (temperature >= 20) {
        return "20°C~22°C: 얇은 가디건, 긴팔, 면바지, 청바지";
    } else if (temperature >= 17) {
        return "17°C~19°C: 얇은 니트, 맨투맨, 가디건, 청바지";
    } else if (temperature >= 12) {
        return "12°C~16°C: 자켓, 가디건, 야상, 스타킹, 청바지, 면바지";
    } else if (temperature >= 9) {
        return "9°C~11°C: 자켓, 트렌치코트, 야상, 니트, 청바지, 스타킹";
    } else if (temperature >= 5) {
        return "5°C~8°C: 코트, 가죽자켓, 히트텍, 니트, 레깅스";
    } else {
        return "4°C 이하: 패딩, 두꺼운코트, 목도리, 기모제품";
    }
};

export const getWindStatus = (windSpeed) => {
    if (windSpeed >= 24.5) {
        return "24.5m/s 이상: 나무의 뿌리가 뽑힌다. 사회에 큰 피해가 발생한다.";
    } else if (windSpeed >= 20.8) {
        return "20.8m/s~24.4m/s: 사회에 약간의 피해를 준다";
    } else if (windSpeed >= 17.2) {
        return "17.2m/s~20.7m/s: 작은 가지가 부러진다. 바람을 향해 걸을 수 없다.";
    } else if (windSpeed >= 13.9) {
        return "13.9m/s~17.1m/s: 나무 전체가 흔들린다.";
    } else if (windSpeed >= 10.8) {
        return "10.8m/s~13.8m/s: 큰 가지가 움직이고 우산을 쓰기 어렵게 만들 정도로 바람이 세게 분다.";
    } else if (windSpeed >= 8) {
        return "8m/s~10.7m/s: 연못 수면에 물결이 일며 나무가 흔들리는 것이 눈에 보인다.";
    } else if (windSpeed >= 5.5) {
        return "5.5m/s~7.9m/s: 먼지가 일며, 종이 조각이 날아오르며, 작은 나무가지가 움직인다.";
    } else if (windSpeed >= 3.4) {
        return "3.4m/s~5.4m/s: 나뭇잎이나 가느다란 가지가 끊임없이 흔들린다.";
    } else if (windSpeed >= 1.6) {
        return "1.6m/s~3.3m/s: 얼굴에 바람기가 느껴지고 나뭇잎이 흔들린다.";
    } else if (windSpeed >= 0.3) {
        return "0.3m/s~1.5m/s: 연기가 옆으로 흘러가는 정도 선선한 바람.";
    } else {
        return "0.3m/s 미만: 연기가 똑바로 올라간다.";
    }
};

export const getColorByPmValue = (value) => {
    if (value === "1") {
        return "#4A90E2";
    } else if (value === "2") {
        return "#00c73c";
    } else if (value === "3") {
        return "orange";
    } else if (value === "4") {
        return "red";
    } else {
        return "black";
    }
};

export const renderWeatherImage = (items, size) => {
    const sky = findValueByCategory(items, "SKY");
    const pty = findValueByCategory(items, "PTY");

    if (pty === "NONE") {
        if (sky === "SUNNY") {
            return <WeatherImage src={"/weather/day_none_sunny.png"} alt={"맑음"} size={size} mt={1} />;
        }
        if (sky === "CLOUDY") {
            return <WeatherImage src={"/weather/day_none_cloud.png"} alt={"구름많음"} size={size} mt={1} />;
        }
        if (sky === "OVERCAST") {
            return <WeatherImage src={"/weather/common_none_overcast.png"} alt={"흐림"} size={size} mt={1} />;
        }
    } else if (pty === "RAIN" || pty === "SHOWER") {
        if (sky === "SUNNY" || sky === "CLOUDY") {
            return <WeatherImage src={"/weather/day_rain_sunny_and_cloud.png"} alt={"구름 많음, 비"} size={size} mt={1} />;
        }
        if (sky === "OVERCAST") {
            return <WeatherImage src={"/weather/common_rain_overcast.png"} alt={"흐림, 비"} size={size} mt={1} />;
        }
    } else if (pty === "SLEET") {
        if (sky === "SUNNY" || sky === "CLOUDY") {
            return <WeatherImage src={"/weather/day_rain_and_snow_sunny_and_cloud.png"} alt={"구름 많음, 눈/비"} size={size} mt={1} />;
        }
        if (sky === "OVERCAST") {
            return <WeatherImage src={"/weather/common_rain_and_snow_overcast.png"} alt={"흐림, 눈/비"} size={size} mt={1} />;
        }
    } else if (pty === "SNOW") {
        if (sky === "SUNNY" || sky === "CLOUDY") {
            return <WeatherImage src={"/weather/day_snow_sunny_and_cloud.png"} alt={"구름 많음, 눈/비"} size={size} mt={1} />;
        }
        if (sky === "OVERCAST") {
            return <WeatherImage src={"/weather/common_snow_overcast.png"} alt={"흐림, 눈/비"} size={size} mt={1} />;
        }
    }
};
