import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import React from "react";
import TodayFortuneText from "./TodayFortuneText";

export default function FortuneBox({ fortune }) {
    const [more, setMore] = React.useState(fortune.content.length === 1);

    return (
        <Box>
            <Typography
                gutterBottom
                sx={{
                    mt: 1.5,
                    fontFamily: "Pretendard-Regular",
                    fontWeight: "bold",
                    fontSize: "4vw",
                    "&::before": { content: "'• '" },
                }}
            >
                {fortune.content[0].name}
            </Typography>
            {fortune.content[0].keyword ? <TodayFortuneText text={fortune.content[0].keyword} /> : <></>}
            {fortune.content[0].desc
                .split(".")
                .slice(0, -1)
                .map((desc, index) => (
                    <TodayFortuneText key={index} text={`${desc}.`} />
                ))}
            {more ? (
                fortune.content.slice(1, -1).map((content, index, array) => {
                    if (index === array.length - 1) {
                        return (
                            <Button onClick={() => setMore(false)} variant="outlined" sx={{ fontWeight: "bold", fontFamily: "Pretendard-Regular", mt: 2 }}>
                                접기
                            </Button>
                        );
                    } else {
                        return (
                            <div key={index}>
                                <Typography
                                    gutterBottom
                                    sx={{
                                        mt: 1.5,
                                        fontFamily: "Pretendard-Regular",
                                        fontWeight: "bold",
                                        fontSize: "4vw",
                                        "&::before": { content: "'• '" },
                                    }}
                                >
                                    {content.name}
                                </Typography>
                                {content.desc
                                    .split(".")
                                    .slice(0, -1)
                                    .map((desc, index) => (
                                        <TodayFortuneText key={index} text={`${desc}.`} />
                                    ))}
                            </div>
                        );
                    }
                })
            ) : (
                <Button onClick={() => setMore(true)} variant="outlined" sx={{ fontWeight: "bold", fontFamily: "Pretendard-Regular", mt: 1 }}>
                    더 보기
                </Button>
            )}
        </Box>
    );
}
