import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Loading from "../components/Loading";
import ServicesSetting from "../components/ServicesSetting";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

export default function UserSetting() {
    const navigate = useNavigate();
    const { user, isLoading } = useUser();

    // 로컬 스토리지에 토큰을 확인하여, 로그인 안 된 상태면 인트로 페이지로 넘긴다.
    useEffect(() => {
        if (isLoading) return; // 로딩 중이면 아래 로직 수행하지 않음

        if (!user) {
            navigate("/intro");
        }
    }, [navigate, user, isLoading]); // isLoading 의존성 추가

    return user ? (
        <Box>
            <ServicesSetting user={user} />
        </Box>
    ) : (
        <Loading />
    );
}
