import React, { useState } from "react";
import { Alert, Avatar, Box, Card, CardContent, ListItemAvatar, ListItemButton, Snackbar } from "@mui/material";
import "../App.css";
import { AddCommentIcon } from "../data/icons";
import Typography from "@mui/material/Typography";
import ServiceReviewModal from "./ServiceReviewModal";

export default function ServiceReview() {
    const [serviceReviewModalOpen, setServiceReviewModalOpen] = useState(false);
    const [submitAlertOpen, setSubmitAlertOpen] = React.useState(false);

    return (
        <Box>
            <Card variant="contained" sx={{ borderRadius: "12px", marginTop: "18px" }}>
                <CardContent sx={{ margin: "10px", padding: "10px", "&:last-child": { paddingBottom: "10px" } }}>
                    <ListItemButton
                        sx={{ width: "100%" }}
                        onClick={() => {
                            setServiceReviewModalOpen(true);
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <AddCommentIcon sx={{}} />
                            </Avatar>
                        </ListItemAvatar>
                        <Typography gutterBottom sx={{ fontFamily: "Orbit-Regular", fontWeight: "bold", fontSize: "3.8vw", color: "grey" }}>
                            <small>(웬만하면 반영되는)</small>서비스 건의함
                        </Typography>
                    </ListItemButton>
                </CardContent>
            </Card>
            <ServiceReviewModal
                open={serviceReviewModalOpen}
                handleClose={() => setServiceReviewModalOpen(false)}
                openSubmitAlert={() => setSubmitAlertOpen(true)}
            />
            <Snackbar
                open={submitAlertOpen}
                autoHideDuration={2000}
                onClose={() => {
                    setSubmitAlertOpen(false);
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    severity="success"
                    sx={{ width: "100%", display: "flex", alignItems: "center", fontSize: "1rem", textAlign: "left" }}
                    onClose={() => {
                        setSubmitAlertOpen(false);
                    }}
                >
                    소중한 의견 감사합니다.
                </Alert>
            </Snackbar>
        </Box>
    );
}
