import * as React from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

export default function FixedLoading() {
    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)", // 투명도 설정
                zIndex: 1000,
            }}
        >
            <CircularProgress color="secondary" size={70} />
        </Box>
    );
}
