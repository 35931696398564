import Typography from "@mui/material/Typography";
import React from "react";

export default function TodayFortuneText({ text }) {
    return (
        <div style={{ marginBottom: 10, marginLeft: 15, display: "flex", alignItems: "baseline" }}>
            <Typography sx={{ fontSize: "3.8vw", "&::before": { content: "'• '" } }}></Typography>
            <Typography
                gutterBottom
                sx={{
                    fontFamily: "Pretendard-Regular",
                    fontSize: "3.8vw",
                    ml: 0.8,
                    mt: 0,
                    mb: 0,
                }}
            >
                {text}
            </Typography>
        </div>
    );
}
