import Typography from "@mui/material/Typography";
import React from "react";
import { getDressCode } from "../../../utils/weatherUtils";

export default function RecommendedDress({ temperatures }) {
    const dressCodes = [...new Set(temperatures.sort().map((temperature) => getDressCode(temperature)))];

    return (
        <>
            <Typography
                gutterBottom
                sx={{
                    fontFamily: "Pretendard-Regular",
                    fontSize: "14px",
                    fontWeight: "500",
                }}
            >
                추천 옷차림
            </Typography>
            {dressCodes.map((dressCode, index) => (
                <div key={index} style={{ display: "flex", alignItems: "baseline" }}>
                    <Typography sx={{ fontSize: "3.6vw", "&::before": { content: "'• '" } }}></Typography>
                    <Typography
                        gutterBottom
                        sx={{
                            fontFamily: "Pretendard-Regular",
                            fontSize: "0.75rem",
                            ml: 0.8,
                            mt: 0,
                            mb: 0,
                        }}
                    >
                        {dressCode}
                    </Typography>
                </div>
            ))}
        </>
    );
}
