// 첫 번째 데이터 세트
const services = [
    {
        type: "TODAY_FORTUNE",
        name: "오늘의 운세",
    },
    {
        type: "WEATHER",
        name: "날씨",
    },
    {
        type: "NEWS",
        name: "뉴스",
    },
];

// 두 번째 데이터 세트
// 필요에 따라 각각 또는 함께 export
export { services };
