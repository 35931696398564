import Box from "@mui/material/Box";
import { Container, TextField } from "@mui/material";
import * as React from "react";

export default function AdminPasswordInput({ password, setPassword }) {
    return (
        <Container maxWidth="xs">
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <TextField
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
            </Box>
        </Container>
    );
}
