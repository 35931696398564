import React, { useEffect, useState } from "react";
import { Box, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import timeOptions from "../data/timeOptions";

export default function TodayFortuneSetting({ todayFortune, setTodayFortune }) {
    // 임시로 입력된 생년월일 값을 저장할 로컬 상태 (6자리 형식 유지)
    const [inputBirthDate, setInputBirthDate] = useState("");

    // DB에서 가져온 YYYY-MM-DD 날짜를 YYMMDD 형식으로 파싱하는 함수
    const parseBirthDateFromDB = (birthDate) => {
        if (!birthDate) return "";
        const [year, month, day] = birthDate.split("-");
        return `${year.slice(2)}${month}${day}`; // YYMMDD 형식으로 변환
    };

    // 날짜 유효성 검증 함수 (존재하는 날짜인지 확인)
    const isValidDate = (year, month, day) => {
        const fullYear = year > 50 ? `19${year}` : `20${year}`; // YY 기준으로 1950년 이후는 1900년대, 그 이전은 2000년대
        const date = new Date(`${fullYear}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`);

        // Date 객체에서 생성된 연, 월, 일이 입력값과 일치하는지 확인
        return (
            date.getFullYear() === parseInt(fullYear, 10) &&
            date.getMonth() + 1 === parseInt(month, 10) && // getMonth()는 0-11이므로 +1 필요
            date.getDate() === parseInt(day, 10)
        );
    };

    // 생년월일 유효성 검증 및 DB 저장 형식으로 변환하는 함수
    const handleBirthDateChange = (event) => {
        const value = event.target.value;

        // 숫자만 허용하는 정규 표현식
        const isNumeric = /^[0-9]*$/.test(value);
        const isValidLength = value.length === 6;

        if (!isNumeric) {
            setTodayFortune({
                ...todayFortune,
                birthDateError: "숫자만 입력할 수 있습니다.",
            });
            setInputBirthDate(value);
        } else if (value.length > 6) {
            setTodayFortune({
                ...todayFortune,
                birthDateError: "생년월일은 6자리여야 합니다.",
            });
            setInputBirthDate(value);
        } else if (isValidLength) {
            const year = parseInt(value.slice(0, 2), 10); // YY
            const month = parseInt(value.slice(2, 4), 10); // MM
            const day = parseInt(value.slice(4, 6), 10); // DD

            if (!isValidDate(year, month, day)) {
                setTodayFortune({
                    ...todayFortune,
                    birthDateError: "유효한 날짜를 입력하세요.",
                });
                setInputBirthDate(value);
            } else {
                // YYYY-MM-DD 형식으로 변환하여 DB에 저장할 수 있도록 설정
                const fullYear = year >= 40 ? `19${year}` : `20${year}`; // 40 이후는 1900년대, 그 이전은 2000년대
                const formattedDate = `${fullYear}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;

                setTodayFortune({
                    ...todayFortune,
                    birthDate: formattedDate, // YYYY-MM-DD 형식으로 저장
                    birthDateError: "",
                });
                setInputBirthDate(value); // 입력 필드에는 여전히 6자리 값 유지
            }
        } else {
            setTodayFortune({
                ...todayFortune,
                birthDateError: "",
            });
            setInputBirthDate(value); // 입력 필드에는 값 계속 업데이트
        }
    };

    // 라디오 버튼, 셀렉트 등 다른 입력값 처리
    const handleTodayFortuneChange = (event) => {
        setTodayFortune({
            ...todayFortune,
            [event.target.name]: event.target.value,
        });
    };

    // 컴포넌트가 처음 렌더링될 때 DB에서 가져온 값을 YYMMDD로 변환
    useEffect(() => {
        if (todayFortune.birthDate && todayFortune.birthDate.includes("-")) {
            const parsedDate = parseBirthDateFromDB(todayFortune.birthDate);
            setInputBirthDate(parsedDate); // 초기 값 설정
        }
    }, [todayFortune.birthDate]);

    return (
        <Box sx={{ pl: 4 }}>
            <FormControl fullWidth sx={{ marginBottom: 1, textAlign: "left" }}>
                <FormLabel sx={{ color: "black", fontSize: "1rem" }}>성별</FormLabel>
                <RadioGroup row aria-label="gender" name="gender" value={todayFortune.gender} onChange={handleTodayFortuneChange}>
                    <FormControlLabel value="M" control={<Radio />} label="남성" sx={{ fontSize: "1rem" }} />
                    <FormControlLabel value="F" control={<Radio />} label="여성" sx={{ fontSize: "1rem" }} />
                </RadioGroup>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2, textAlign: "left" }}>
                <FormLabel sx={{ color: "black", fontSize: "1rem", mb: 1 }}>양력 / 음력</FormLabel>
                <Select
                    name="calendarType"
                    value={todayFortune.calendarType}
                    onChange={handleTodayFortuneChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ fontSize: "1rem" }}
                >
                    <MenuItem value="SOLAR">양력</MenuItem>
                    <MenuItem value="LUNAR_GENERAL">음력 평달</MenuItem>
                    <MenuItem value="LUNAR_LEAP">음력 윤달</MenuItem>
                </Select>
            </FormControl>

            {/* 생년월일 6자리 입력 필드 */}
            <FormControl fullWidth sx={{ mb: 2, textAlign: "left" }}>
                <FormLabel sx={{ color: "black", fontSize: "1rem", mb: 1 }}>생년월일 6자리</FormLabel>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="birthDate"
                    value={inputBirthDate} // 6자리 생년월일 입력값
                    onChange={handleBirthDateChange}
                    error={!!todayFortune.birthDateError} // 에러 여부에 따른 스타일링
                    helperText={todayFortune.birthDateError} // 에러 메시지 또는 가이드라인
                    inputProps={{
                        maxLength: 6, // 최대 6자리로 제한
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                    }}
                    sx={{
                        // 폰트 크기 설정
                        "& .MuiInputBase-input": {
                            fontSize: "1rem", // 원하는 폰트 크기로 설정]
                        },
                    }}
                />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2, textAlign: "left" }}>
                <FormLabel sx={{ color: "black", fontSize: "1rem", mb: 1 }}>태어난 시간</FormLabel>
                <Select
                    name="birthTime"
                    value={todayFortune.birthTime}
                    onChange={handleTodayFortuneChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ fontSize: "1rem" }}
                >
                    <MenuItem value="" disabled>
                        시간 선택
                    </MenuItem>
                    {timeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
