import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotificationDetailModal from "../components/notifications/NotificationDetailModal";

export default function NotificationDetail() {
    const navigate = useNavigate();
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    let { id } = useParams();

    useEffect(() => {
        if (id) {
            setDetailModalOpen(true);
        }
    }, [id]);

    return (
        <NotificationDetailModal
            id={id}
            open={detailModalOpen}
            handleClose={() => {
                setDetailModalOpen(false);
                navigate("/notifications");
            }}
        />
    );
}
